
import { computed, defineComponent } from 'vue';
import CampoNumericoMedida from '@/core/components/Tela/CampoNumericoMedida.vue';

export default defineComponent({
  name: 'ProdutoPesoMedidas',
  props: {
    peso: {
      type: Number,
      required: true,
    },
    comprimento: {
      type: Number,
      required: true,
    },
    altura: {
      type: Number,
      required: true,
    },
    largura: {
      type: Number,
      required: true,
    },
  },
  components: {
    CampoNumericoMedida,
  },
  emits: ['update:peso', 'update:comprimento', 'update:altura', 'update:largura'],
  setup(props, { emit }) {
    const computedPeso = computed({
      get: () => props.peso,
      set: (valor: number) => {
        emit('update:peso', valor);
      },
    });

    const computedComprimento = computed({
      get: () => props.comprimento,
      set: (valor: number) => {
        emit('update:comprimento', valor);
      },
    });

    const computedAltura = computed({
      get: () => props.altura,
      set: (valor: number) => {
        emit('update:altura', valor);
      },
    });

    const computedLargura = computed({
      get: () => props.largura,
      set: (valor: number) => {
        emit('update:largura', valor);
      },
    });

    return {
      props,
      computedPeso,
      computedComprimento,
      computedAltura,
      computedLargura,
    };
  },
});
