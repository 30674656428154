
import { computed, defineComponent, reactive } from 'vue';
import { IOption } from '@/core/models/AntDesign/IOption';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import { useTelaBase } from '@/core/composables/TelaBase';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import ServicoCaracteristica from '@/servicos/Cadastros/ServicoCaracteristica';

export default defineComponent({
  name: 'SelecionarCaracteristicaItem',
  props: {
    varios: {
      type: Boolean,
      default: false,
    },
    codigoSelecionado: {
      type: Number || undefined,
    },
    codigosSelecionados: {
      type: Array as () => number[],
      default: () => [],
    },
    codigoCaracteristica: {
      type: Number,
      default: 0,
    },
    limparSelecao: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: 'Digite para selecionar',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:codigoSelecionado', 'update:codigosSelecionados', 'change'],
  setup(props, { emit }) {
    const { montaOpcoesComListaConsultaRapida } = useTelaBase();

    let debounce = 0;
    const servicoCaracteristica = new ServicoCaracteristica();

    const state = reactive({
      listaCaracteristicasItens: [] as IOption[],
      buscandoDados: false,
      emDigitacao: false,
    });

    async function obterCaracteristicaItemPorCodigo(valor: any) {
      if (state.emDigitacao) { return; }
      if (!UtilitarioGeral.valorValido(valor)) { return; }
      if (!(valor > 0)) { return; }

      if (props.varios) {
        if (props.codigosSelecionados.length === 0) { return; }
      } else if (props.codigoSelecionado === 0) {
        return;
      }
      let efetuarConsulta = true;

      if (UtilitarioGeral.validaLista(state.listaCaracteristicasItens)) {
        if (props.varios) {
          const listaCodigosConsulta = state.listaCaracteristicasItens.map((c) => c.value);
          const diferencas = props.codigosSelecionados.filter((c) => !listaCodigosConsulta.includes(c));
          if (!UtilitarioGeral.validaLista(diferencas)) {
            efetuarConsulta = false;
          }
        } else {
          const existeLista = state.listaCaracteristicasItens.find((c) => c.value === props.codigoSelecionado);
          if (existeLista !== undefined) {
            efetuarConsulta = false;
          }
        }
      }

      if (!efetuarConsulta) return;

      state.listaCaracteristicasItens = [];

      const parametrosConsultaRapida: IParametrosConsultaRapida = {
        apenasAtivos: false, recursoAssociado: '',
      };

      if (props.varios) {
        parametrosConsultaRapida.filtrarPorVariosCodigos = true;
        parametrosConsultaRapida.valores = valor;
      } else {
        parametrosConsultaRapida.filtrarPorCodigo = true;
        parametrosConsultaRapida.valor = valor;
      }

      const listaCaracteristicasItens = await servicoCaracteristica.consultaRapidaItens(props.codigoCaracteristica, parametrosConsultaRapida);
      state.listaCaracteristicasItens = montaOpcoesComListaConsultaRapida(listaCaracteristicasItens);
    }

    async function pesquisarCaracteristicaItem(valorPesquisa: any) {
      state.emDigitacao = true;

      state.buscandoDados = true;
      state.listaCaracteristicasItens = [];
      clearTimeout(debounce);
      debounce = setTimeout(async () => {
        const parametrosConsultaRapida: IParametrosConsultaRapida = {
          valor: valorPesquisa, apenasAtivos: true, recursoAssociado: '', quantidadeRegistros: 100,
        };

        const listaCaracteristicasItens = await servicoCaracteristica.consultaRapidaItens(props.codigoCaracteristica, parametrosConsultaRapida);
        state.buscandoDados = false;
        state.listaCaracteristicasItens = montaOpcoesComListaConsultaRapida(listaCaracteristicasItens);
      }, 600);
    }

    function obterCaracteristicaItem(): any {
      if (props.codigoSelecionado !== undefined) {
        if (props.codigoSelecionado > 0) {
          obterCaracteristicaItemPorCodigo(props.codigoSelecionado);
        } else {
          return undefined;
        }
      }
      return props.codigoSelecionado;
    }

    const computedCodigoSelecionado = computed({
      get: () => obterCaracteristicaItem(),
      set: (val: number) => {
        emit('update:codigoSelecionado', val);
      },
    });

    function obterCaracteristicaItems(): number[] {
      obterCaracteristicaItemPorCodigo(props.codigosSelecionados);
      return props.codigosSelecionados;
    }

    const computedCodigosSelecionados = computed({
      get: () => obterCaracteristicaItems(),
      set: (val: number[]) => {
        emit('update:codigosSelecionados', val);
      },
    });

    function change() {
      emit('change', props.varios ? props.codigosSelecionados : props.codigoSelecionado);
    }

    return {
      props,
      state,
      pesquisarCaracteristicaItem,
      computedCodigoSelecionado,
      computedCodigosSelecionados,
      change,
    };
  },
});
