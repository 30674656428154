
import {
  computed, defineComponent, reactive, ref,
} from 'vue';
import draggable from 'vuedraggable';
import Icone from '@/core/components/Icone.vue';
import { ICaracteristicaItem } from '@/models/Entidades/Cadastros/ICaracteristica';
import Card from '@/core/components/Tela/Card.vue';
import SelecionarSimNao from '@/core/components/Tela/SelecionarSimNao.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { useTelaBase } from '@/core/composables/TelaBase';

export default defineComponent({
  name: 'ItensCores',
  emits: ['update:itensCores'],
  props: {
    itensCores: {
      type: Array as () => ICaracteristicaItem[],
      required: true,
    },
  },
  components: {
    draggable,
    Icone,
    Card,
    SelecionarSimNao,
    MensagemSemDados,
  },
  setup(props, { emit }) {
    const { apresentarMensagemAlerta } = useTelaBase();

    const campoNovoValorItem = ref<HTMLElement | null>(null);

    const state = reactive({
      novoValor: '',
      novaCor: '',
    });

    const computedItensCores = computed({
      get: () => props.itensCores,
      set: (val: ICaracteristicaItem[]) => {
        emit('update:itensCores', val);
      },
    });

    function removerItem(index:number) {
      computedItensCores.value.splice(index, 1);
    }

    function defineFocoCampoNovoItem() {
      if (campoNovoValorItem.value) {
        campoNovoValorItem.value.focus();
      }
    }

    function adicionarItem() {
      if (!UtilitarioGeral.valorValido(state.novoValor)) {
        apresentarMensagemAlerta('Por favor, defina um nome para a cor!');
        defineFocoCampoNovoItem();
        return;
      }

      const itens = computedItensCores.value;
      const itemCaracteristica:ICaracteristicaItem = {} as ICaracteristicaItem;
      itemCaracteristica.codigo = 0;
      itemCaracteristica.codigoCaracteristica = 0;
      itemCaracteristica.valor = state.novoValor;
      itemCaracteristica.ativo = true;
      itens.push(itemCaracteristica);
      state.novoValor = '';
      state.novaCor = '';
      emit('update:itensCores', itens);
    }

    return {
      state,
      computedItensCores,
      campoNovoValorItem,
      removerItem,
      adicionarItem,
    };
  },
});
