
import {
  defineComponent, onBeforeMount, reactive,
} from 'vue';
import { Modal } from 'ant-design-vue';
import storeSistema from '@/store/storeSistema';
import { useTelaBase } from '@/core/composables/TelaBase';
import TituloPadrao from '@/core/components/Tela/TituloPadrao.vue';
import ColunaGrade from '@/core/components/Tela/ColunaGrade.vue';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import PreferenciasColuna from '@/core/components/Preferencias/ColunaTabela.vue';
import ProdutoModal from '@/views/Cadastros/Produtos/ProdutoModal.vue';
import { IProduto } from '@/models/Entidades/Cadastros/IProduto';
import { EStatusRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { ECustomRenderRow, IColumn } from '@/core/models/AntDesign/IColumn';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import { useGradeBase } from '@/core/composables/GradeBase';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import { useCrudBase } from '@/core/composables/CrudBase';
import ServicoSistema from '@/servicos/Sistema/ServicoSistema';
import ServicoProduto from '@/servicos/Cadastros/ServicoProduto';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import BuscaGeral from '@/core/components/BuscaAvancada/BuscaGeral.vue';
import Paginacao from '@/core/components/Tela/Paginacao.vue';
import TornarProdutoVariacaoDrawer from '@/components/Cadastros/Produtos/TornarProdutoVariacaoDrawer.vue';
import { ETipoProduto } from '@/models/Enumeradores/ETipoProduto';

export default defineComponent({
  name: 'ProdutoIndex',
  components: {
    TituloPadrao,
    ColunaGrade,
    Paginacao,
    Icone,
    Card,
    MensagemSemDados,
    PreferenciasColuna,
    BuscaGeral,
    ProdutoModal,
    TornarProdutoVariacaoDrawer,
  },
  setup() {
    const {
      telaBase, preencherDadosRota, telaOperacaoIncluir, telaOperacaoEditar, apresentarMensagemSucesso,
    } = useTelaBase();

    const { crudBase, exibirModal } = useCrudBase();

    const {
      gradeBase, salvarFiltrosBuscaAvancada, salvarOrdenacaoBuscaAvancada, salvarPersonalizacaoColunas, redefinirPersonalizacaoColunas,
      carregarPreferenciasGrade, preparaParametrosConsulta, salvarTodasPreferencias,
    } = useGradeBase();

    const servicoProduto = new ServicoProduto();
    gradeBase.ordenacaoSelecionada = [];

    gradeBase.colunasPadrao = [
      {
        title: 'Código', dataIndex: 'codigo', key: 'CodigoProduto', position: 0, visible: false, ordering: false,
      },
      {
        title: 'Nome', dataIndex: 'nome', key: 'NomeProduto', position: 1, visible: true, ordering: true, fixed: 'left', ellipsis: true, width: 400, align: 'left', customRenderRow: ECustomRenderRow.LinkAcao,
      },
      {
        title: 'Marca', dataIndex: 'marca', key: 'MarcaProduto', position: 3, visible: true, ordering: true, align: 'left', ellipsis: true, width: 150,
      },
      {
        title: 'Status', dataIndex: 'ativo', key: 'StatusProduto', position: 5, visible: true, align: 'left', customRenderRow: ECustomRenderRow.TagAtivo, width: 80,
      },
      {
        title: 'NCM', dataIndex: 'ncm', key: 'ncmProduto', position: 6, visible: true, ordering: true, align: 'left',
      },
      {
        title: 'Ações', key: 'acoes', position: 7, visible: true, fixed: 'right', width: 120, align: 'center', customRenderRow: ECustomRenderRow.IconeAcoes,
      },
    ];

    gradeBase.colunasMobile = [
      {
        title: 'Produto', dataIndex: '', key: 'Produto', position: 0, visible: true, align: 'left',
      },
    ];

    const state = reactive({
      dados: [] as IProduto[],
      buscaRapida: '',
      exibirPersonalizarColunas: false,
      exibirBuscaAvancada: false,
      exibirPersonalizacaoTela: false,
      apresentarDrawerProdutoVariacao: false,
      produtosTornarVariacao: [] as IProduto[],
    });

    async function buscarDados() {
      state.dados = [];
      const parametrosConsulta = preparaParametrosConsulta([], gradeBase.paginacao.current, gradeBase.paginacao.pageSize, Number(gradeBase.totalRegistrosComMascara.replaceAll('.', '')));
      parametrosConsulta.valorBuscaRapida = state.buscaRapida;
      telaBase.carregando = true;
      const listaProdutoPaginado = await servicoProduto.buscaAvancada(parametrosConsulta, gradeBase.filtrosAplicados);
      state.dados = listaProdutoPaginado.dados;
      gradeBase.paginacaoApi = listaProdutoPaginado.metaData;
      telaBase.carregando = false;
    }

    async function carregarPreferencias() {
      telaBase.preferencias = await new ServicoSistema().obterPreferenciaRecurso(telaBase.identificadorRecurso, []);
      if (telaBase.preferencias !== null) {
        carregarPreferenciasGrade(telaBase.preferencias);
      }
    }

    onBeforeMount(async () => {
      preencherDadosRota();
      telaBase.propriedadesConsulta = await servicoProduto.obterPropriedadesConsulta();
      if (telaBase.propriedadesConsulta.length > 0) {
        gradeBase.buscaAvancada.filtros = telaBase.propriedadesConsulta.filter((item) => item.filtro === true);
        gradeBase.buscaAvancada.ordenacao = telaBase.propriedadesConsulta.filter((item) => item.ordenacao === true);
      }
      gradeBase.buscaAvancada.filtrosAdicionados = [];
      gradeBase.buscaAvancada.chaveFiltrosAdicionados = 0;
      await carregarPreferencias();
      await buscarDados();
    });

    function novoProduto() {
      exibirModal(telaOperacaoIncluir());
    }

    function editarProduto(codigo: number) {
      exibirModal(telaOperacaoEditar(codigo));
    }

    async function excluirProduto(codigo: number) {
      const retornoExclusao = await servicoProduto.excluir(codigo);
      if (retornoExclusao.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso(retornoExclusao.mensagem);
        state.dados = state.dados.filter((produto: IProduto) => produto.codigo !== codigo);
      } else if (retornoExclusao.status === EStatusRetornoRequisicao.Alerta) {
        Modal.warning({
          title: 'Não foi possível concluir a exclusão!',
          content: retornoExclusao.mensagem,
        });
      }
    }

    async function confirmaExclusao(produto: IProduto) {
      Modal.confirm({
        title: 'Você confirma a exclusão do Produto:',
        content: `${produto.nome} ?`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => { await excluirProduto(produto.codigo); },
      });
    }

    async function sincronizarProduto(codigo: number) {
        const parametrosConsulta: IParametrosConsulta = {} as IParametrosConsulta;
        parametrosConsulta.codigosSelecionados = [];

        parametrosConsulta.codigosSelecionados.push(codigo);
        const resultadoBusca = await servicoProduto.buscaAvancada(parametrosConsulta);
        if (UtilitarioGeral.validaLista(resultadoBusca.dados)) {
          const index = state.dados.findIndex((c) => c.codigo === codigo);
          if (index >= 0) {
              state.dados[index] = resultadoBusca.dados[0];
            } else {
              state.dados.push(resultadoBusca.dados[0]);
            }
        }
    }

    function apresentarBuscaAvancada() {
      state.exibirPersonalizarColunas = false;
      state.exibirBuscaAvancada = !state.exibirBuscaAvancada;
    }

    function apresentarPersonalizarColuna() {
      state.exibirBuscaAvancada = false;
      state.exibirPersonalizarColunas = !state.exibirPersonalizarColunas;
    }

    function apresentarPersonalizacaoTela() {
      state.exibirPersonalizacaoTela = true;
    }

    async function aplicarFiltros() {
      gradeBase.paginacao.current = 1;
      await buscarDados();
    }

    function textoCodigosSelecionados(): string {
      if (gradeBase.codigosSelecionados.length === 1) { return '1 - Produto selecionado'; }

      if (gradeBase.codigosSelecionados.length > 1) { return `${gradeBase.codigosSelecionados.length} - Produtos selecionados`; }

      return '';
    }

    function obtemDataIndex(coluna: IColumn) {
      if (coluna.dataIndex !== undefined) {
        return coluna.dataIndex;
      }
      return '';
    }

    function apresentarDrawerProdutoVariacao() {
      state.apresentarDrawerProdutoVariacao = true;
    }

    function preencheCodigosSelecionados(codigosSelecionados: any) {
      state.produtosTornarVariacao = [];
      gradeBase.codigosSelecionados = codigosSelecionados;
      if (UtilitarioGeral.validaLista(gradeBase.codigosSelecionados)) {
        gradeBase.codigosSelecionados.forEach((codigoSelecionado) => {
        const produto = state.dados.find((c) => c.codigo === codigoSelecionado);
        if (produto !== undefined) {
          if (produto.tipo === ETipoProduto.ProdutoSimples) {
              state.produtosTornarVariacao.push(produto);
          }
        }
        });
      }
    }

    return {
      storeSistema,
      telaBase,
      gradeBase,
      crudBase,
      state,
      novoProduto,
      editarProduto,
      confirmaExclusao,
      excluirProduto,
      sincronizarProduto,
      apresentarBuscaAvancada,
      apresentarPersonalizarColuna,
      apresentarPersonalizacaoTela,
      aplicarFiltros,
      buscarDados,
      ECustomRenderRow,
      ETipoArquivo,
      salvarFiltrosBuscaAvancada,
      salvarOrdenacaoBuscaAvancada,
      salvarPersonalizacaoColunas,
      salvarTodasPreferencias,
      redefinirPersonalizacaoColunas,
      preencheCodigosSelecionados,
      textoCodigosSelecionados,
      obtemDataIndex,
      apresentarDrawerProdutoVariacao,
    };
  },
});
