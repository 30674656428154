
import { computed, defineComponent } from 'vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import { IEstoqueItem } from '@/models/Entidades/Cadastros/Estoques/IEstoqueItem';
import { IEstoque } from '@/models/Entidades/Cadastros/Estoques/IEstoque';

export default defineComponent({
    name: 'ProdutoEstoques',
    props: {
        itensEstoques: {
            type: Array as () => IEstoqueItem[],
            required: true,
        },
        estoquesCadastrados: {
            type: Array as () => IEstoque[],
            required: true,
        },
    },
    components: {
        MensagemSemDados,
    },
    emits: ['update:itensEstoques'],
    setup(props, { emit }) {
        const computedItensEstoque = computed({
            get: () => props.itensEstoques,
            set: (valor: IEstoqueItem[]) => {
                emit('update:itensEstoques', valor);
            },
        });

        function obtemNomeEstoque(codigoEstoque: number) {
            const estoque = props.estoquesCadastrados.find((c) => c.codigo === codigoEstoque);
            if (estoque !== undefined) {
                return estoque.nome;
            }

            return '';
        }

        return {
            props,
            computedItensEstoque,
            obtemNomeEstoque,
        };
    },
});
