
import {
  computed, defineComponent, reactive,
} from 'vue';
import draggable from 'vuedraggable';
import { Modal } from 'ant-design-vue';
import Icone from '@/core/components/Icone.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IProdutoImagem } from '@/models/Entidades/Cadastros/IProduto';
import ServicoProduto from '@/servicos/Cadastros/ServicoProduto';
import { IRetornoArquivo } from '@/core/models/IRetornoArquivo';

export default defineComponent({
  name: 'ProdutoImagens',
  props: {
    imagens: {
      type: Array as () => IProdutoImagem[],
      required: true,
    },
  },
  components: {
    Icone,
    draggable,
  },
  emits: ['update:imagens'],
  setup(props, { emit }) {
    const servicoProduto = new ServicoProduto();
    const state = reactive({
      enviandoImagens: false,
      excluindoImagens: false,
    });

    const computedImagens = computed({
      get: () => props.imagens,
      set: (valor: IProdutoImagem[]) => {
        emit('update:imagens', valor);
      },
    });

    function organizarImagens(estrategiaArrastar : boolean) {
      if (UtilitarioGeral.validaLista(computedImagens.value)) {
        const imagensParaOrdenar = UtilitarioGeral.instanciaObjetoLocal(computedImagens.value) as IProdutoImagem[];
        let ordem = 1;
        const imagens: IProdutoImagem[] = [];
        const imagemPrincipal = imagensParaOrdenar.find((c) => c.principal === true);
        if (imagemPrincipal !== undefined && !estrategiaArrastar) {
          imagemPrincipal.ordem = ordem;
          imagens.push(imagemPrincipal);
        }

        for (let index = 0; index < imagensParaOrdenar.length; index += 1) {
          ordem += 1;

          if (estrategiaArrastar) {
            if (estrategiaArrastar && index === 0) {
              imagensParaOrdenar[index].principal = true;
            } else {
              imagensParaOrdenar[index].principal = false;
            }
            imagensParaOrdenar[index].ordem = ordem;
            imagens.push(imagensParaOrdenar[index]);
          } else if (imagemPrincipal !== undefined) {
            if (imagemPrincipal.imagem !== imagensParaOrdenar[index].imagem) {
              imagensParaOrdenar[index].ordem = ordem;
              imagens.push(imagensParaOrdenar[index]);
            }
          } else {
            imagensParaOrdenar[index].ordem = ordem;
            imagens.push(imagensParaOrdenar[index]);
          }
        }
        computedImagens.value = imagens;
      }
    }

    function defineImagemPrincipal(imagem:string) {
      if (UtilitarioGeral.validaLista(computedImagens.value)) {
        const indexPrincipal = computedImagens.value.findIndex((c) => c.imagem === imagem);

        for (let i = 0; i < computedImagens.value.length; (i += 1)) {
          computedImagens.value[i].principal = false;
        }

        if (indexPrincipal >= 0) {
          computedImagens.value[indexPrincipal].principal = true;
        }
        organizarImagens(false);
      }
    }

    async function removerImagem(produtoImagem: IProdutoImagem) {
      state.excluindoImagens = true;
      const index = computedImagens.value.findIndex((c) => c.imagem === produtoImagem.imagem);
      if (index !== -1) {
        computedImagens.value.splice(index, 1);
        organizarImagens(false);
      }
      state.excluindoImagens = false;
    }

    function selecionarImagemUpload() {
      const elemento = document.getElementById('selecionarImagemProduto');
      if (elemento !== null) {
        elemento.click();
      }
    }
    function confirmaExclusao(produtoImagem: IProdutoImagem) {
      Modal.confirm({
        title: 'Você confirma a exclusão da imagem selecionada?',
        content: '',
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: () => { removerImagem(produtoImagem); },
      });
    }
    function novaImagem(retorno: IRetornoArquivo) {
      const produtoImagem:IProdutoImagem = {} as IProdutoImagem;
      produtoImagem.codigo = 0;
      produtoImagem.codigoProdutoDefinicao = 0;
      produtoImagem.imagem = retorno.linkArquivo;
      produtoImagem.principal = computedImagens.value.length === 0;
      computedImagens.value.push(produtoImagem);
    }
    async function enviarImagens(event:any) {
      if (UtilitarioGeral.validaLista(event.target.files)) {
        state.enviandoImagens = true;
        const listaArquivos = await servicoProduto.enviarImagens(event.target.files);
        listaArquivos.forEach((arquivo) => {
          if (UtilitarioGeral.valorValido(arquivo.linkArquivo)) {
            novaImagem(arquivo);
          }
        });
        organizarImagens(false);
      }
      state.enviandoImagens = false;
    }

    async function dropImagem(event:any) {
      state.enviandoImagens = true;
      const listaArquivos = await servicoProduto.enviarImagens(event.dataTransfer.files);
      listaArquivos.forEach((arquivo) => {
        if (UtilitarioGeral.valorValido(arquivo.linkArquivo)) {
          novaImagem(arquivo);
        }
      });
      organizarImagens(false);
      state.enviandoImagens = false;
    }

    return {
      props,
      computedImagens,
      state,
      dropImagem,
      enviarImagens,
      confirmaExclusao,
      selecionarImagemUpload,
      defineImagemPrincipal,
      organizarImagens,
    };
  },
});
