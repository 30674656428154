import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_select, {
      value: _ctx.computedValor,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedValor) = $event)),
      onChange: _ctx.defineValorSelecionado,
      class: _normalizeClass('ss-tamanho-completo ' + _ctx.props.classCss),
      "data-coluna": _ctx.props.dataAttributeColuna,
      disabled: _ctx.props.disabled
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_select_option, { value: 0 }, {
          default: _withCtx(() => [
            _createTextVNode("0 - Nacional, exceto as indicadas nos códigos 3, 4, 5 e 8")
          ]),
          _: 1
        }),
        _createVNode(_component_a_select_option, { value: 1 }, {
          default: _withCtx(() => [
            _createTextVNode("1 - Estrangeira, importação direta, exceto a indicada no código 6")
          ]),
          _: 1
        }),
        _createVNode(_component_a_select_option, { value: 2 }, {
          default: _withCtx(() => [
            _createTextVNode("2 - Estrangeira, adquirida no mercado interno, exceto a indicada no código 7")
          ]),
          _: 1
        }),
        _createVNode(_component_a_select_option, { value: 3 }, {
          default: _withCtx(() => [
            _createTextVNode("3 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 40% e inferior ou igual a 70%")
          ]),
          _: 1
        }),
        _createVNode(_component_a_select_option, { value: 4 }, {
          default: _withCtx(() => [
            _createTextVNode("4 - Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam as legislações citadas nos Ajustes")
          ]),
          _: 1
        }),
        _createVNode(_component_a_select_option, { value: 5 }, {
          default: _withCtx(() => [
            _createTextVNode("5 - Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40%")
          ]),
          _: 1
        }),
        _createVNode(_component_a_select_option, { value: 6 }, {
          default: _withCtx(() => [
            _createTextVNode("6 - Estrangeira, importação direta, sem similar nacional, constante em lista da CAMEX e gás natural")
          ]),
          _: 1
        }),
        _createVNode(_component_a_select_option, { value: 7 }, {
          default: _withCtx(() => [
            _createTextVNode("7 - Estrangeira, adquirida no mercado interno, sem similar nacional, constante lista CAMEX e gás natural")
          ]),
          _: 1
        }),
        _createVNode(_component_a_select_option, { value: 8 }, {
          default: _withCtx(() => [
            _createTextVNode("8 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 70%")
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["value", "onChange", "class", "data-coluna", "disabled"])
  ]))
}