import { reactive } from 'vue';
import { IItemConsultaRapida } from '../models/Consulta/IItemConsultaRapida';
import { IOption } from '../models/AntDesign/IOption';
import UtilitarioGeral from '../utilitarios/UtilitarioGeral';
import { IParametrosConsultaRapida } from '../models/Consulta/IParametrosConsultaRapida';

export interface ISelecionarBase {
  listaOpcoes: IOption[];
  listaItensConsultaRapida: IItemConsultaRapida[];
  buscandoDados: boolean;
  emDigitacao: boolean;
  valorUltimaPesquisa: string;
}
export interface IRetornoSelecionarBase {
  selecionarBase: ISelecionarBase;
  montaOpcoesComListaConsultaRapida(listaItens: IItemConsultaRapida[]): IOption[];
  verificacaoPreBusca: (valor: any) => boolean;
  verificacaoUltimaPesquisa: (valor: any) => boolean;
  aguardarConclusaoCarregamento: (computedCodigoSelecionado: number, computedCodigosSelecionados: number[]) => boolean;
  comportamentoPadraoSemResultado: () => void;
  instanciaParametrosConsultaRapidaPorCodigo: (valor: any) => IParametrosConsultaRapida;
  instanciaParametrosConsultaRapidaPesquisa: (valorPesquisa: any) => IParametrosConsultaRapida;

}
export function useSelecionarBase(props: any, emit: any): IRetornoSelecionarBase {
  const selecionarBase = reactive({
    listaOpcoes: [] as IOption[],
    listaItensConsultaRapida: [] as IItemConsultaRapida[],
    buscandoDados: false,
    emDigitacao: false,
    valorUltimaPesquisa: '',
  });

  function montaOpcoesComListaConsultaRapida(listaItens: IItemConsultaRapida[]): IOption[] {
    let listaOpcoes: IOption[] = [];
    if (UtilitarioGeral.validaLista(listaItens)) {
      listaOpcoes = listaItens.map((item) => ({
        label: item.textoIdentificacao,
        value: Number(item.codigo),
      }));
    }

    return listaOpcoes;
  }

  function verificacaoPreBusca(valor: any): boolean {
    if (selecionarBase.emDigitacao) { return false; }
    if (!UtilitarioGeral.valorValido(valor)) { return false; }
    if (!(valor > 0)) { return false; }

    if (props.varios) {
      if (props.codigosSelecionados.length === 0) { return false; }
    } else if (props.codigoSelecionado === 0) {
      return false;
    }
    // Verifica se já tem uma lista de opções e tenta procurar o valor selecionado nela pra evitar consumo no servidor
    if (UtilitarioGeral.validaLista(selecionarBase.listaOpcoes)) {
      if (props.varios) {
        const listaCodigosConsulta = selecionarBase.listaOpcoes.map((c) => c.value);
        const diferencas = props.codigosSelecionados.filter((c: number) => !listaCodigosConsulta.includes(c));
        if (!UtilitarioGeral.validaLista(diferencas)) {
          return false;
        }
      } else {
        const existeLista = selecionarBase.listaOpcoes.find((c) => c.value === props.codigoSelecionado);
        if (existeLista !== undefined) {
          return false;
        }
      }
    }

    return true;
  }

  function verificacaoUltimaPesquisa(valor: any): boolean {
    if (valor === selecionarBase.valorUltimaPesquisa) {
      if (UtilitarioGeral.validaLista(selecionarBase.listaOpcoes) && selecionarBase.listaOpcoes.length > 1) { return false; }
      if (UtilitarioGeral.validaLista(selecionarBase.listaItensConsultaRapida) && selecionarBase.listaItensConsultaRapida.length > 1) { return false; }
    }

    return true;
  }

  function aguardarConclusaoCarregamento(computedCodigoSelecionado: number, computedCodigosSelecionados: number[]): boolean {
    return (!selecionarBase.emDigitacao && !UtilitarioGeral.validaLista(selecionarBase.listaOpcoes) && (UtilitarioGeral.validaCodigo(computedCodigoSelecionado) || UtilitarioGeral.validaLista(computedCodigosSelecionados)));
  }

  function comportamentoPadraoSemResultado(): void {
    if (!UtilitarioGeral.validaLista(selecionarBase.listaOpcoes)) {
      if (props.varios) {
        if (UtilitarioGeral.validaLista(props.codigosSelecionados)) {
          emit('update:codigosSelecionados', []);
        }
      } else if (UtilitarioGeral.validaCodigo(props.codigoSelecionado)) {
        emit('update:codigoSelecionado', 0);
      }
    }
  }

  function instanciaParametrosConsultaRapidaPorCodigo(valor: any): IParametrosConsultaRapida {
    const parametrosConsultaRapida: IParametrosConsultaRapida = {
      apenasAtivos: false, recursoAssociado: '',
    };

    if (props.varios) {
      parametrosConsultaRapida.filtrarPorVariosCodigos = true;
      parametrosConsultaRapida.valores = valor;
    } else {
      parametrosConsultaRapida.filtrarPorCodigo = true;
      parametrosConsultaRapida.valor = valor;
    }

    return parametrosConsultaRapida;
  }

  function instanciaParametrosConsultaRapidaPesquisa(valorPesquisa: any): IParametrosConsultaRapida {
    const parametrosConsultaRapida: IParametrosConsultaRapida = {
      valor: valorPesquisa, apenasAtivos: true, recursoAssociado: '',
    };

    return parametrosConsultaRapida;
  }

  return {
    selecionarBase,
    montaOpcoesComListaConsultaRapida,
    verificacaoPreBusca,
    verificacaoUltimaPesquisa,
    aguardarConclusaoCarregamento,
    comportamentoPadraoSemResultado,
    instanciaParametrosConsultaRapidaPorCodigo,
    instanciaParametrosConsultaRapidaPesquisa,
  };
}
