
import { computed, defineComponent } from 'vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import SelecionarDefinicaoPreco from '../SelecionarDefinicaoPreco.vue';

export default defineComponent({
    name: 'ProdutoPrecos',
    props: {
        definicaoPreco: {
            type: Number,
            required: true,
        },
        precoCusto: {
            type: Number,
            required: true,
        },
        calcularPrecoMarkup: {
            type: Boolean,
            required: true,
        },
        markup: {
            type: Number,
            required: true,
        },
        precoVenda: {
            type: Number,
            required: true,
        },
    },
    components: {
        SelecionarDefinicaoPreco,
        CampoNumerico,
    },
    emits: ['update:definicaoPreco', 'update:precoCusto', 'update:calcularPrecoMarkup', 'update:markup', 'update:precoVenda'],
    setup(props, { emit }) {
        const computedDefinicaoPreco = computed({
            get: () => props.definicaoPreco,
            set: (valor: number) => {
                emit('update:definicaoPreco', valor);
            },
        });

        const computedPrecoCusto = computed({
            get: () => props.precoCusto,
            set: (valor: number) => {
                emit('update:precoCusto', valor);
            },
        });

        const computedCalcularPrecoMarkup = computed({
            get: () => props.calcularPrecoMarkup,
            set: (valor: boolean) => {
                emit('update:calcularPrecoMarkup', valor);
            },
        });

        const computedMarkup = computed({
            get: () => props.markup,
            set: (valor: number) => {
                emit('update:markup', valor);
            },
        });

        const computedPrecoVenda = computed({
            get: () => props.precoVenda,
            set: (valor: number) => {
                emit('update:precoVenda', valor);
            },
        });

        return {
            props,
            computedDefinicaoPreco,
            computedPrecoCusto,
            computedCalcularPrecoMarkup,
            computedMarkup,
            computedPrecoVenda,
        };
    },
});
