import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "ss-tamanho-completo" }
const _hoisted_2 = { class: "ant-col ant-col-xs-24 ant-col-xl-8" }
const _hoisted_3 = ["value"]
const _hoisted_4 = { class: "ant-col ant-col-xs-24 ant-col-xl-5" }
const _hoisted_5 = ["onUpdate:modelValue"]
const _hoisted_6 = { class: "ant-col ant-col-xs-24 ant-col-xl-8" }
const _hoisted_7 = ["onUpdate:modelValue"]
const _hoisted_8 = { class: "ant-col ant-col-xs-24 ant-col-xl-3" }
const _hoisted_9 = ["onUpdate:modelValue"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mensagem_sem_dados = _resolveComponent("mensagem-sem-dados")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.props.estoquesCadastrados.length <= 0)
      ? (_openBlock(), _createBlock(_component_mensagem_sem_dados, {
          key: 0,
          mensagem: "Nenhum estoque foi cadastrado ainda."
        }))
      : (_openBlock(), _createBlock(_component_a_form, {
          key: 1,
          layout: "vertical"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.computedItensEstoque, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: 'ITEMST' + index,
                class: "ant-row"
              }, [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_a_form_item, {
                    label: "Estoque",
                    class: "ss-margin-campos"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("input", {
                        type: "text",
                        class: "ant-input",
                        value: _ctx.obtemNomeEstoque(item.codigoEstoque),
                        disabled: ""
                      }, null, 8, _hoisted_3)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_a_form_item, {
                    label: "Quantidade",
                    class: "ss-margin-campos",
                    rules: [{ required: true }]
                  }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("input", {
                        type: "number",
                        class: "ant-input",
                        "onUpdate:modelValue": ($event: any) => ((item.quantidade) = $event)
                      }, null, 8, _hoisted_5), [
                        [_vModelText, item.quantidade]
                      ])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_a_form_item, {
                    label: "Localização",
                    class: "ss-margin-campos"
                  }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("input", {
                        type: "text",
                        maxlength: "250",
                        class: "ant-input",
                        "onUpdate:modelValue": ($event: any) => ((item.localizacao) = $event)
                      }, null, 8, _hoisted_7), [
                        [_vModelText, item.localizacao]
                      ])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_a_form_item, {
                    label: "Prazo Adicional",
                    class: "ss-margin-campos"
                  }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("input", {
                        type: "number",
                        class: "ant-input",
                        "onUpdate:modelValue": ($event: any) => ((item.prazoAdicional) = $event)
                      }, null, 8, _hoisted_9), [
                        [_vModelText, item.prazoAdicional]
                      ])
                    ]),
                    _: 2
                  }, 1024)
                ])
              ]))
            }), 128))
          ]),
          _: 1
        }))
  ]))
}