
import { computed, defineComponent, ref } from 'vue';
import { useSelecionarBase } from '@/core/composables/SelecionarBase';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import ServicoCategoria from '@/servicos/Cadastros/ServicoCategoria';

export default defineComponent({
  name: 'SelecionarCategoria',
  props: {
    varios: {
      type: Boolean,
      default: false,
    },
    codigoSelecionado: {
      type: Number || undefined,
    },
    codigosSelecionados: {
      type: Array as () => number[],
      default: () => [],
    },
    nomeSelecionado: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Digite para selecionar',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    dataAttributeColuna: {
      type: String,
      default: '',
    },
    classCss: {
      type: String,
      default: '',
    },
    limparSelecao: {
      type: Boolean,
      default: true,
    },
    omitirCategorias: {
      type: Array as () => number[],
    },
  },
  components: {
  },
  emits: ['update:codigoSelecionado', 'update:codigosSelecionados', 'update:nomeSelecionado', 'change'],
  setup(props, { emit }) {
    const {
      selecionarBase, montaOpcoesComListaConsultaRapida, verificacaoPreBusca, verificacaoUltimaPesquisa, aguardarConclusaoCarregamento,
      comportamentoPadraoSemResultado, instanciaParametrosConsultaRapidaPorCodigo, instanciaParametrosConsultaRapidaPesquisa,
    } = useSelecionarBase(props, emit);

    let debounce = 0;
    const servicoCategoria = new ServicoCategoria();
    const logoCategoria = ref('');

    function preencheListaOpcoes(): void {
      if (UtilitarioGeral.validaLista(selecionarBase.listaItensConsultaRapida)) {
        if (props.omitirCategorias !== undefined) {
          props.omitirCategorias.forEach((codigoCategoria) => {
            selecionarBase.listaItensConsultaRapida = selecionarBase.listaItensConsultaRapida.filter((c) => c.codigo !== codigoCategoria);
          });
        }
        selecionarBase.listaOpcoes = montaOpcoesComListaConsultaRapida(selecionarBase.listaItensConsultaRapida);
      }
    }

    async function obterCategoriaPorCodigo(valor: any) {
      if (!verificacaoPreBusca(valor)) return;

      selecionarBase.listaOpcoes = [];

      const parametrosConsultaRapida = instanciaParametrosConsultaRapidaPorCodigo(valor);
      selecionarBase.listaItensConsultaRapida = await servicoCategoria.consultaRapida(parametrosConsultaRapida);
      preencheListaOpcoes();
      comportamentoPadraoSemResultado();
    }

    async function pesquisarCategoria(valorPesquisa: any) {
      selecionarBase.emDigitacao = true;

      if (!verificacaoUltimaPesquisa(valorPesquisa)) return;

      selecionarBase.buscandoDados = true;
      selecionarBase.listaOpcoes = [];
      clearTimeout(debounce);
      debounce = setTimeout(async () => {
        const parametrosConsultaRapida = instanciaParametrosConsultaRapidaPesquisa(valorPesquisa);
        selecionarBase.listaItensConsultaRapida = await servicoCategoria.consultaRapida(parametrosConsultaRapida);
        preencheListaOpcoes();
        selecionarBase.valorUltimaPesquisa = valorPesquisa;
        selecionarBase.buscandoDados = false;
      }, 600);
    }

    const computedNomeSelecionado = computed({
      get: () => props.nomeSelecionado,
      set: (val: string) => {
        emit('update:nomeSelecionado', val);
      },
    });

    function preencherNomeCategoria(codigoCategoria: number) {
      const option = selecionarBase.listaOpcoes.find((c) => c.value === codigoCategoria);
      if (option?.label !== undefined && option?.label !== '') {
        computedNomeSelecionado.value = option?.label;
      } else {
        computedNomeSelecionado.value = '';
      }
    }

    function obterCategoria(): any {
      if (props.codigoSelecionado === 0) {
        return undefined;
      }
      obterCategoriaPorCodigo(props.codigoSelecionado);
      if (props.codigoSelecionado !== undefined && props.codigoSelecionado > 0) {
        preencherNomeCategoria(props.codigoSelecionado);
      }
      return props.codigoSelecionado;
    }

    const computedCodigoSelecionado = computed({
      get: () => obterCategoria(),
      set: (val: number) => {
        preencherNomeCategoria(val);
        emit('update:codigoSelecionado', val);
      },
    });

    function obterCategorias(): number[] {
      obterCategoriaPorCodigo(props.codigosSelecionados);
      return props.codigosSelecionados;
    }

    const computedCodigosSelecionados = computed({
      get: () => obterCategorias(),
      set: (val: number[]) => {
        emit('update:codigosSelecionados', val);
      },
    });

    function change() {
      emit('change', props.varios ? props.codigosSelecionados : props.codigoSelecionado);
    }

    return {
      props,
      selecionarBase,
      UtilitarioGeral,
      computedCodigoSelecionado,
      computedCodigosSelecionados,
      pesquisarCategoria,
      aguardarConclusaoCarregamento,
      change,
      logoCategoria,
    };
  },
});
