
import { reactive, defineComponent, onBeforeMount } from 'vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import { IIntegracao } from '@/models/Entidades/Integracao';
import { IAnuncio } from '@/models/Entidades/IAnuncio';
import { useGradeBase } from '@/core/composables/GradeBase';
import storeSistema from '@/store/storeSistema';
import ServicoAnuncio from '@/servicos/ServicoAnuncio';
import { ECustomRenderRow } from '@/core/models/AntDesign/IColumn';
import { IProduto } from '@/models/Entidades/Cadastros/IProduto';
import AnuncioDrawer from '@/components/Anuncios/AnuncioDrawer.vue';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import ServicoIntegracao from '@/servicos/ServicoIntegracao';

export default defineComponent({
    name: 'ProdutoAnuncios',
    props: {
        produto: {
            type: Object as () => IProduto,
            required: true,
        },
        integracoes: {
            type: Array as () => IIntegracao[],
            required: true,
        },
    },
    components: {
        MensagemSemDados,
        AnuncioDrawer,
    },
    setup(props) {
        const servicoAnuncio = new ServicoAnuncio();
        const servicoIntegracao = new ServicoIntegracao();

        const state = reactive({
            carregando: false,
            apresentarDrawerAnuncio: false,
            codigoAnuncio: 0,
            codigoProduto: 0,
            anuncios: [] as IAnuncio[],
        });

        const { gradeBase } = useGradeBase();

        function preencherColunas() {
            if (storeSistema.state.layoutMobile) {
                gradeBase.colunas = [
                    {
                        title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: false,
                    },
                    {
                        title: 'Anúncio', dataIndex: 'anuncio', key: 'Anuncio', position: 2, visible: true, ordering: true, align: 'left',
                    },
                ];
            } else {
                gradeBase.colunas = [
                    {
                        title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: false,
                    },
                    {
                        title: 'Marketplace', dataIndex: 'codigoIntegracao', key: 'Marketplace', position: 2, visible: true, ellipsis: true, width: 20, fixed: 'left',
                    },
                    {
                        title: 'Cod.Anúncio', dataIndex: 'codigoAnuncio', key: 'CodigoAnuncio', position: 3, visible: true, ellipsis: true, width: 40, fixed: 'left',
                    },
                    {
                        title: 'Título', dataIndex: 'titulo', key: 'Titulo', position: 3, visible: true, ellipsis: true, width: 80, customRenderRow: ECustomRenderRow.LinkAcao, fixed: 'left',
                    },
                    {
                        title: 'Preço R$', dataIndex: 'preco', key: 'Preco', position: 5, visible: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 30,
                    },
                    {
                        title: 'Status', dataIndex: 'statusAnuncio', key: 'StatusAnuncio', position: 6, visible: true, ellipsis: true, width: 25,
                    },
                ];
            }
        }

        async function buscarAnuncios() {
            state.carregando = true;
            preencherColunas();
            state.anuncios = await servicoAnuncio.obterAnunciosProduto(props.produto.codigo);
            state.carregando = false;
        }

        onBeforeMount(async () => {
            await buscarAnuncios();
        });

        function gerenciarAnuncio(codigoAnuncio: number) {
            state.codigoAnuncio = codigoAnuncio;
            state.codigoProduto = props.produto.codigo;
            console.log('produto', props.produto);
            console.log('codigo produto', props.produto.codigo);
            console.log('anuncio', state.codigoAnuncio);
            state.apresentarDrawerAnuncio = true;
        }

        function obtemLogoIntegracao(codigoIntegracao: number): string {
            const integracao = props.integracoes.find((c) => c.codigo === codigoIntegracao);
            if (integracao !== undefined) {
                return servicoIntegracao.obtemLogoIntegracao(integracao.tipo);
            }
            return '';
        }

        return {
            props,
            gradeBase,
            state,
            UtilitarioMascara,
            UtilitarioGeral,
            gerenciarAnuncio,
            ECustomRenderRow,
            servicoAnuncio,
            buscarAnuncios,
            obtemLogoIntegracao,
        };
    },
});
