
import { computed, defineComponent, reactive } from 'vue';
import Icone from '@/core/components/Icone.vue';
import SelecionarCategoria from '@/components/Cadastros/SelecionarCategoria.vue';
import SelecionarMarca from '@/components/Cadastros/SelecionarMarca.vue';
import SelecionarNcm from '../SelecionarNcm.vue';
import SelecionarGenero from '../SelecionarGenero.vue';
import { IProduto } from '@/models/Entidades/Cadastros/IProduto';
import SelecionarOrigemMercadoria from '@/components/Cadastros/SelecionarOrigemMercadoria.vue';
import SelecionarTipoProduto from '@/components/Cadastros/Produtos/SelecionarTipoProduto.vue';
import { ETipoProduto } from '@/models/Enumeradores/ETipoProduto';

export default defineComponent({
  name: 'ProdutoDadosPrincipais',
  props: {
    produto: {
      type: Object as () => IProduto,
      required: true,
    },
  },
  components: {
    Icone,
    SelecionarTipoProduto,
    SelecionarOrigemMercadoria,
    SelecionarCategoria,
    SelecionarMarca,
    SelecionarNcm,
    SelecionarGenero,
  },
  emits: ['update:produto', 'update:categoriasSelecionadas', 'alterarVariacao'],
  setup(props, { emit }) {
    const state = reactive({
      ncmSelecionado: '',
    });

    const computedProduto = computed({
      get: () => props.produto,
      set: (produto: IProduto) => {
        emit('update:produto', produto);
      },
    });

    function mudarComportamentoVariacao() {
      emit('alterarVariacao');
    }

    return {
      props,
      state,
      computedProduto,
      ETipoProduto,
      mudarComportamentoVariacao,
    };
  },
});
