
  import {
    watch, defineComponent, reactive,
  } from 'vue';
  import { Modal } from 'ant-design-vue';
  import Icone from '@/core/components/Icone.vue';
  import Card from '@/core/components/Tela/Card.vue';
  import SelecionarCaracteristica from '@/components/Cadastros/Caracteristicas/SelecionarCaracteristica.vue';
  import SelecionarCaracteristicaItem from '@/components/Cadastros/Caracteristicas/SelecionarCaracteristicaItem.vue';
  import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
  import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
  import { useTelaBase } from '@/core/composables/TelaBase';
  import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
  import { useModalBase } from '@/core/composables/ModalBase';
  import {
  IProduto, IProdutoCaracteristica, IProdutoDefinicao, IProdutoImagem,
  } from '@/models/Entidades/Cadastros/IProduto';
  import ServicoProduto from '@/servicos/Cadastros/ServicoProduto';
  import { EGenero } from '@/models/Enumeradores/EGenero';
  import { ETipoProduto } from '@/models/Enumeradores/ETipoProduto';
  import { ICaracteristica } from '@/models/Entidades/Cadastros/ICaracteristica';
  import ServicoCaracteristica from '@/servicos/Cadastros/ServicoCaracteristica';
  import CaracteristicaModal from '@/views/Cadastros/Caracteristicas/CaracteristicaModal.vue';
  import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import { IDTOTornarProdutoVariacao } from '@/models/DTO/Cadastros/Produtos/IDTOTornarProdutoVariacao';

  export default defineComponent({
    name: 'TornarProdutoVariacaoDrawer',
    props: {
      visivel: {
        type: Boolean,
        required: true,
      },
      produtosSimples: {
          type: Array as () => IProduto[],
          required: true,
      },
    },
    components: {
      Card,
      Icone,
      SelecionarCaracteristica,
      SelecionarCaracteristicaItem,
      CaracteristicaModal,
      RequisicaoModal,
    },
    emits: ['update:visivel', 'sincronizarRegistro'],
    setup(props, { emit }) {
      const { apresentarMensagemSucesso } = useTelaBase();
      const servicoProduto = new ServicoProduto();

      const {
        modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso, sincronizarRegistro,
      } = useModalBase(props, emit);

      const state = reactive({
        carregando: false,
        produtosSimples: [] as IProduto[],
        produtoVariacao: {} as IProduto,
        codigosCaracteristicasVariacao: [] as number[],
        caracteristicasCadastradas: [] as ICaracteristica[],
        variacoesNomeProduto: [] as string[],
        telaOperacao: {} as ITelaOperacao,
        apresentarModalCaracteristica: false,
      });

      function objetoInicial() {
        state.codigosCaracteristicasVariacao = [];
        state.produtoVariacao = {} as IProduto;
        state.produtoVariacao.codigo = 0;
        state.produtoVariacao.nome = '';
        state.produtoVariacao.descricao = '';
        state.produtoVariacao.genero = EGenero.Unissex;
        state.produtoVariacao.tipo = ETipoProduto.ProdutoComVariacao;
        state.produtoVariacao.tempoGarantia = 0;
        state.produtoVariacao.ativo = true;
        state.produtoVariacao.definicoes = [];
        state.produtoVariacao.caracteristicas = [];
      }

      function apresentarCadastroCaracteristica(codigoCaracteristica:number) {
        state.telaOperacao = {} as ITelaOperacao;
        state.telaOperacao.codigoRegistro = codigoCaracteristica;
        state.telaOperacao.codigoRegistroDuplicar = 0;
        state.telaOperacao.codigoRegistroPai = 0;
        state.apresentarModalCaracteristica = true;
      }

      function obterIdentificacaoProdutoSimples(codigoInterno:string): string {
        let identificacao = '';

        for (let index = 0; index < state.produtosSimples.length; index += 1) {
          if (state.produtosSimples[index].definicoes[0].codigoInterno === codigoInterno) {
            identificacao = state.produtosSimples[index].nome.replace(state.produtoVariacao.nome, '').trim();
            break;
          }
        }
        return identificacao;
      }

      function obterNomeCaracteristica(codigoCaracteristica:number): string {
        const caracteristica = state.caracteristicasCadastradas.find((c) => c.codigo === codigoCaracteristica);
        if (caracteristica !== undefined) {
          return caracteristica.nome;
        }

        return '';
      }

      function preparaVariacoes() {
        state.produtoVariacao.definicoes = [];
        if (UtilitarioGeral.validaLista(state.codigosCaracteristicasVariacao)) {
            state.produtosSimples.forEach((produtoSimples) => {
              const definicao:IProdutoDefinicao = {} as IProdutoDefinicao;
              definicao.codigo = 0;
              definicao.codigoProduto = 0;
              definicao.codigoInterno = produtoSimples.definicoes[0].codigoInterno;
              definicao.codigoBarras = produtoSimples.definicoes[0].codigoBarras;
              definicao.uRLVideo = produtoSimples.definicoes[0].uRLVideo;
              definicao.peso = produtoSimples.definicoes[0].peso;
              definicao.comprimento = produtoSimples.definicoes[0].comprimento;
              definicao.altura = produtoSimples.definicoes[0].altura;
              definicao.largura = produtoSimples.definicoes[0].largura;
              definicao.definicaoPreco = produtoSimples.definicoes[0].definicaoPreco;
              definicao.precoCusto = produtoSimples.definicoes[0].precoCusto;
              definicao.calcularPrecoMarkup = produtoSimples.definicoes[0].calcularPrecoMarkup;
              definicao.markup = produtoSimples.definicoes[0].markup;
              definicao.precoVenda = produtoSimples.definicoes[0].precoVenda;
              definicao.ativa = true;
              definicao.estoques = [];
              definicao.imagens = [];
              definicao.caracteristicasVariacao = [];

              if (UtilitarioGeral.validaLista(produtoSimples.definicoes[0].imagens)) {
                produtoSimples.definicoes[0].imagens.forEach((imagem) => {
                  const produtoImagem:IProdutoImagem = {} as IProdutoImagem;
                  produtoImagem.codigo = 0;
                  produtoImagem.codigoProdutoDefinicao = 0;
                  produtoImagem.principal = imagem.principal;
                  produtoImagem.imagem = imagem.imagem;
                  produtoImagem.ordem = imagem.ordem;
                  produtoImagem.vinculos = [];
                  definicao.imagens.push(produtoImagem);
                });
              }

              const variacao = produtoSimples.nome.replace(state.produtoVariacao.nome, '').trim();

              if (UtilitarioGeral.validaLista(state.codigosCaracteristicasVariacao)) {
                let ordem = 1;
                state.codigosCaracteristicasVariacao.forEach((codigoCaracteristica) => {
                  const produtoCaracteristicaVariacao:IProdutoCaracteristica = {} as IProdutoCaracteristica;
                  produtoCaracteristicaVariacao.codigo = 0;
                  produtoCaracteristicaVariacao.codigoProduto = 0;
                  produtoCaracteristicaVariacao.codigoProdutoDefinicao = 0;
                  const caracteristica = state.caracteristicasCadastradas.find((c) => c.codigo === codigoCaracteristica);
                  if (caracteristica !== undefined) {
                    produtoCaracteristicaVariacao.codigoCaracteristica = codigoCaracteristica;
                    produtoCaracteristicaVariacao.tipo = caracteristica.tipo;
                    const itemCaracteristica = caracteristica.itens.find((c) => c.valor.toLowerCase() === variacao.toLowerCase());
                    if (itemCaracteristica !== undefined) {
                      produtoCaracteristicaVariacao.codigoCaracteristicaItem = itemCaracteristica.codigo;
                    }
                  }

                  produtoCaracteristicaVariacao.variacao = true;
                  produtoCaracteristicaVariacao.ordem = ordem;
                  produtoCaracteristicaVariacao.vinculos = [];
                  definicao.caracteristicasVariacao.push(produtoCaracteristicaVariacao);
                  ordem += 1;
                });
              }

              state.produtoVariacao.definicoes.push(definicao);
            });
        }
      }

      async function salvar() {
        let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };
        apresentarBarraProgresso();
        const objeto:IDTOTornarProdutoVariacao = {} as IDTOTornarProdutoVariacao;
        objeto.produtoVariacao = state.produtoVariacao;
        objeto.produtosSimples = state.produtosSimples;
        retorno = await servicoProduto.tornarProdutoComVariacao(objeto);

        ocultarBarraProgresso();

        if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
          sincronizarRegistro(retorno.codigoRegistro);
          apresentarMensagemSucesso(retorno.mensagem);
          modalBase.computedVisivel = false;
        } else {
          apresentarRetornoRequisicao(retorno);
        }
      }

      async function confirmaConclusao() {
        Modal.confirm({
          title: 'Os produtos selecionados vão se tornar um único cadastro de produto.',
          content: 'Você confirma essa operação ?',
          okText: 'Sim',
          okType: 'danger',
          cancelText: 'Não',
          autoFocusButton: null,
          centered: true,
          onOk: async () => { await salvar(); },
        });
      }

      watch(async () => modalBase.computedVisivel, async () => {
        objetoInicial();
        if (modalBase.computedVisivel) {
            state.carregando = true;
            state.caracteristicasCadastradas = await new ServicoCaracteristica().obterTodasCaracteristicas();

            const codigosProdutos:number[] = [];
            props.produtosSimples.forEach((produto) => {
              codigosProdutos.push(produto.codigo);
            });
            state.produtosSimples = await servicoProduto.obterVarios(codigosProdutos);

            // Extrair todos os nomes dos produtos
            const nomeProdutos = props.produtosSimples.map(({ nome }) => nome);

            // Dividir o primeiro produto em partes
            const nomePrimeiroProduto = nomeProdutos[0].split(' ');

            // Encontrar o prefixo comum entre os produtos
            let nomeComum = '';
            for (let i = 0; i < nomePrimeiroProduto.length; i += 1) {
              const parte = nomePrimeiroProduto.slice(0, i + 1).join(' ');
              if (nomeProdutos.every((nome) => nome.startsWith(parte))) {
                nomeComum = parte;
              } else {
                break;
              }
            }

            state.produtoVariacao.nome = nomeComum;
            state.variacoesNomeProduto = [];
            state.variacoesNomeProduto = nomeProdutos.map((nome) => nome.replace(nomeComum, '').trim());

            state.produtosSimples.forEach((produtoSimples) => {
              state.produtoVariacao.origem = produtoSimples.origem;

              if (!UtilitarioGeral.validaCodigo(state.produtoVariacao.codigoCategoria) && UtilitarioGeral.validaCodigo(produtoSimples.codigoCategoria)) {
                state.produtoVariacao.codigoCategoria = produtoSimples.codigoCategoria;
              }

              if (!UtilitarioGeral.validaCodigo(state.produtoVariacao.codigoMarca) && UtilitarioGeral.validaCodigo(produtoSimples.codigoMarca)) {
                state.produtoVariacao.codigoMarca = produtoSimples.codigoMarca;
              }

              if (!UtilitarioGeral.validaCodigo(state.produtoVariacao.genero) && UtilitarioGeral.validaCodigo(produtoSimples.genero)) {
                state.produtoVariacao.genero = produtoSimples.genero;
              }

              if (!UtilitarioGeral.valorValido(state.produtoVariacao.descricao) && UtilitarioGeral.valorValido(produtoSimples.descricao)) {
                state.produtoVariacao.descricao = produtoSimples.descricao;
              }

              if (!UtilitarioGeral.valorValido(state.produtoVariacao.ncm) && UtilitarioGeral.valorValido(produtoSimples.ncm)) {
                state.produtoVariacao.ncm = produtoSimples.ncm;
              }

              if (!UtilitarioGeral.valorValido(state.produtoVariacao.modelo) && UtilitarioGeral.valorValido(produtoSimples.modelo)) {
                state.produtoVariacao.modelo = produtoSimples.modelo;
              }

              if (!UtilitarioGeral.validaCodigo(state.produtoVariacao.tempoGarantia) && UtilitarioGeral.validaCodigo(produtoSimples.tempoGarantia)) {
                state.produtoVariacao.tempoGarantia = produtoSimples.tempoGarantia;
              }

              if (!UtilitarioGeral.valorValido(state.produtoVariacao.informacaoGarantia) && UtilitarioGeral.valorValido(produtoSimples.informacaoGarantia)) {
                state.produtoVariacao.informacaoGarantia = produtoSimples.informacaoGarantia;
              }
            });

            state.carregando = false;
        }
      });

      return {
        props,
        state,
        modalBase,
        UtilitarioGeral,
        confirmaConclusao,
        obterNomeCaracteristica,
        preparaVariacoes,
        obterIdentificacaoProdutoSimples,
        apresentarCadastroCaracteristica,
      };
    },
  });
  