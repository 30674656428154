
import { defineComponent, reactive, watch } from 'vue';
import Icone from '@/core/components/Icone.vue';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import VinculoIntegracao from '@/components/Vinculo/VinculoIntegracao.vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import ServicoCaracteristica from '@/servicos/Cadastros/ServicoCaracteristica';
import { ICaracteristica } from '@/models/Entidades/Cadastros/ICaracteristica';
import { ETipoCaracteristica } from '@/models/Enumeradores/ETipoCaracteristica';
import SelecionarTipoCaracteristica from '@/components/Cadastros/Caracteristicas/SelecionarTipoCaracteristica.vue';
import ItensListaSimples from '@/components/Cadastros/Caracteristicas/ItensListaSimples.vue';
import ItensCores from '@/components/Cadastros/Caracteristicas/ItensCores.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { ETipoVinculo } from '@/models/Enumeradores/ETipoVinculo';

export default defineComponent({
    name: 'CaracteristicaModal',
    props: {
        visivel: {
            type: Boolean,
        },
        operacao: {
            type: Object as () => ITelaOperacao,
            required: true,
        },
    },
    components: {
        Icone,
        SelecionarTipoCaracteristica,
        ItensListaSimples,
        ItensCores,
        RequisicaoModal,
        VinculoIntegracao,
    },
    emits: ['sincronizarRegistro', 'update:operacao', 'update:visivel'],
    setup(props, { emit }) {
        const { telaBase, apresentarMensagemSucesso } = useTelaBase();
        const {
            modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso,
            defineNovaOperacao, defineTextoPadraoBotoes, sincronizarRegistro,
        } = useModalBase(props, emit);
        const servicoCaracteristica = new ServicoCaracteristica();
        telaBase.identificadorRecurso = 'CADASTRO_CARACTERISTICA';

        const state = reactive({
            caracteristica: {} as ICaracteristica,
            apresentarModalVinculoCaracteristica: false,
        });

        function objetoInicial() {
            state.apresentarModalVinculoCaracteristica = false;
            state.caracteristica = {} as ICaracteristica;
            state.caracteristica.codigo = 0;
            state.caracteristica.nome = '';
            state.caracteristica.descricao = '';
            state.caracteristica.tipo = ETipoCaracteristica.ListaSimples;
            state.caracteristica.ativo = true;
            state.caracteristica.itens = [];
            state.caracteristica.vinculos = [];
        }

        function atualizaOrdemItens() {
            if (state.caracteristica.tipo === ETipoCaracteristica.Cor
                || state.caracteristica.tipo === ETipoCaracteristica.ListaSimples
                || state.caracteristica.tipo === ETipoCaracteristica.ListaSubItens) {
                if (UtilitarioGeral.validaLista(state.caracteristica.itens)) {
                    for (let indexItem = 0; indexItem < state.caracteristica.itens.length; (indexItem += 1)) {
                        state.caracteristica.itens[indexItem].ordem = indexItem;
                    }
                }
            }
        }

        async function salvar(salvarNovo: boolean) {
            let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };

            apresentarBarraProgresso();
            atualizaOrdemItens();
            if (state.caracteristica.codigo === 0) {
                retorno = await servicoCaracteristica.incluir(state.caracteristica);
            } else {
                retorno = await servicoCaracteristica.alterar(state.caracteristica);
            }

            ocultarBarraProgresso();

            if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
                if (state.caracteristica.codigo === 0) {
                    sincronizarRegistro(retorno.codigoRegistro);
                } else {
                    sincronizarRegistro(props.operacao.codigoRegistro);
                }
                apresentarMensagemSucesso(retorno.mensagem);
                if (salvarNovo) {
                    objetoInicial();
                    defineNovaOperacao(props.operacao);
                } else {
                    modalBase.computedVisivel = false;
                }
            } else {
                apresentarRetornoRequisicao(retorno);
            }
        }

        watch(async () => modalBase.computedVisivel, async () => {
            telaBase.carregando = true;
            objetoInicial();
            if (modalBase.computedVisivel) {
                defineTextoPadraoBotoes((props.operacao.codigoRegistro === 0), true);
                if (props.operacao.codigoRegistro > 0) {
                    state.caracteristica = await servicoCaracteristica.obter(props.operacao.codigoRegistro);
                }
            }
            telaBase.carregando = false;
        });

        return {
            telaBase,
            props,
            modalBase,
            state,
            ETipoCaracteristica,
            salvar,
            objetoInicial,
            ETipoVinculo,
        };
    },
});
