import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ant-row" }
const _hoisted_2 = { class: "ant-col ant-col-xs-24 ant-col-xl-6" }
const _hoisted_3 = { class: "ant-col ant-col-xs-24 ant-col-xl-6" }
const _hoisted_4 = { class: "ant-col ant-col-xs-24 ant-col-xl-6" }
const _hoisted_5 = { class: "ant-col ant-col-xs-24 ant-col-xl-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_campo_numerico_medida = _resolveComponent("campo-numerico-medida")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_form, { layout: "vertical" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_a_form_item, {
              label: "Peso",
              class: "ss-margin-campos"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_campo_numerico_medida, {
                  peso: true,
                  valor: _ctx.computedPeso,
                  "onUpdate:valor": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedPeso) = $event))
                }, null, 8, ["valor"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_a_form_item, {
              label: "Comprimento",
              class: "ss-margin-campos"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_campo_numerico_medida, {
                  centimetro: true,
                  valor: _ctx.computedComprimento,
                  "onUpdate:valor": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.computedComprimento) = $event))
                }, null, 8, ["valor"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_a_form_item, {
              label: "Altura",
              class: "ss-margin-campos"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_campo_numerico_medida, {
                  centimetro: true,
                  valor: _ctx.computedAltura,
                  "onUpdate:valor": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.computedAltura) = $event))
                }, null, 8, ["valor"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_a_form_item, {
              label: "Largura",
              class: "ss-margin-campos"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_campo_numerico_medida, {
                  centimetro: true,
                  valor: _ctx.computedLargura,
                  "onUpdate:valor": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.computedLargura) = $event))
                }, null, 8, ["valor"])
              ]),
              _: 1
            })
          ])
        ])
      ]),
      _: 1
    })
  ]))
}