
import { defineComponent, reactive } from 'vue';
import Card from '@/core/components/Tela/Card.vue';
import Icone from '@/core/components/Icone.vue';
import ProdutoEstoques from '@/components/Cadastros/Produtos/ProdutoEstoques.vue';
import ProdutoPrecos from '@/components/Cadastros/Produtos/ProdutoPrecos.vue';
import ProdutoPesoMedidas from '@/components/Cadastros/Produtos/ProdutoPesoMedidas.vue';
import ProdutoImagens from '@/components/Cadastros/Produtos/ProdutoImagens.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { useTelaBase } from '@/core/composables/TelaBase';
import { IProdutoDefinicao } from '@/models/Entidades/Cadastros/IProduto';
import { ICaracteristica } from '@/models/Entidades/Cadastros/ICaracteristica';
import ServicoProduto from '@/servicos/Cadastros/ServicoProduto';
import { IEstoque } from '@/models/Entidades/Cadastros/Estoques/IEstoque';

export default defineComponent({
  name: 'ProdutoEditarVariacao',
  props: {
    nomeProduto: {
      type: String,
      required: true,
    },
    codigoCaracteristica: {
      type: Number,
    },
    definicoesProdutos: {
      type: Array as () => IProdutoDefinicao[],
      required: true,
    },
    caracteristicasCadastradas: {
      type: Array as () => ICaracteristica[],
      required: true,
    },
    estoquesCadastrados: {
      type: Array as () => IEstoque[],
      required: true,
    },
  },
  components: {
    Card,
    Icone,
    ProdutoEstoques,
    ProdutoPrecos,
    ProdutoPesoMedidas,
    ProdutoImagens,
  },
  emits: ['update:definicoesProdutos'],
  setup(props, { emit }) {
    const { apresentarMensagemAlerta } = useTelaBase();

    const servicoProduto = new ServicoProduto();

    const state = reactive({
      apresentarModalVariacao: false,
      produtoDefinicao: {} as IProdutoDefinicao,
      caracteristicasCadastradas: [] as ICaracteristica[],
      indexDefinicao: 0,
      preparandoCaracteristicas: false,
    });

    function editarVariacao(produtoDefinicao: IProdutoDefinicao, indexDefinicao: number) {
      state.apresentarModalVariacao = false;

      if (UtilitarioGeral.validaLista(props.caracteristicasCadastradas)) {
        state.produtoDefinicao = UtilitarioGeral.instanciaObjetoLocal(produtoDefinicao);
        state.indexDefinicao = indexDefinicao;
        state.apresentarModalVariacao = true;
      } else {
        apresentarMensagemAlerta('Nenhuma característica foi encontrada!');
      }
    }

    function confirmarDadosVariacao() {
      const definicoesProdutos = UtilitarioGeral.instanciaObjetoLocal(props.definicoesProdutos);
      definicoesProdutos[state.indexDefinicao] = state.produtoDefinicao;
      emit('update:definicoesProdutos', definicoesProdutos);
      state.apresentarModalVariacao = false;
    }

    function obtemDescricaoVariacao(codigoCaracteristicaItem: number): string {
      const dadosVariacao = props.caracteristicasCadastradas.find((c) => c.codigo === props.codigoCaracteristica);
      if (dadosVariacao !== undefined) {
        const variacaoItem = dadosVariacao.itens.find((c) => c.codigo === codigoCaracteristicaItem);
        if (variacaoItem !== undefined) {
          return variacaoItem.valor;
        }
      }
      return '';
    }

    return {
      props,
      state,
      UtilitarioGeral,
      editarVariacao,
      confirmarDadosVariacao,
      obtemDescricaoVariacao,
      servicoProduto,
    };
  },
});
