
import {
  defineComponent, reactive, ref, watch,
} from 'vue';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import ProdutoDadosPrincipais from '@/components/Cadastros/Produtos/ProdutoDadosPrincipais.vue';
import ProdutoEstoques from '@/components/Cadastros/Produtos/ProdutoEstoques.vue';
import ProdutoPrecos from '@/components/Cadastros/Produtos/ProdutoPrecos.vue';
import ProdutoPesoMedidas from '@/components/Cadastros/Produtos/ProdutoPesoMedidas.vue';
import ProdutoImagens from '@/components/Cadastros/Produtos/ProdutoImagens.vue';
import ProdutoCaracteristicas from '@/components/Cadastros/Produtos/ProdutoCaracteristicas.vue';
import ProdutoAdicionarVariacao from '@/components/Cadastros/Produtos/ProdutoAdicionarVariacao.vue';
import ProdutoEditarVariacao from '@/components/Cadastros/Produtos/ProdutoEditarVariacao.vue';
import ProdutoAnuncios from '@/components/Cadastros/Produtos/ProdutoAnuncios.vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import ServicoProduto from '@/servicos/Cadastros/ServicoProduto';
import { IProduto, IProdutoDefinicao } from '@/models/Entidades/Cadastros/IProduto';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { EGenero } from '@/models/Enumeradores/EGenero';
import { ETipoProduto } from '@/models/Enumeradores/ETipoProduto';
import { ICaracteristica } from '@/models/Entidades/Cadastros/ICaracteristica';
import ServicoCaracteristica from '@/servicos/Cadastros/ServicoCaracteristica';
import ServicoEstoque from '@/servicos/Cadastros/ServicoEstoque';
import ServicoIntegracao from '@/servicos/ServicoIntegracao';
import { EDefinicaoPreco } from '@/models/Enumeradores/EDefinicaoPreco';
import { IEstoque } from '@/models/Entidades/Cadastros/Estoques/IEstoque';
import { IEstoqueItem } from '@/models/Entidades/Cadastros/Estoques/IEstoqueItem';
import { IIntegracao } from '@/models/Entidades/Integracao';
import { ETipoIntegracao } from '@/models/Enumeradores/ETipoIntegracao';

export default defineComponent({
  name: 'ProdutoModal',
  props: {
    visivel: {
      type: Boolean,
    },
    operacao: {
      type: Object as () => ITelaOperacao,
      required: true,
    },
  },
  components: {
    Icone,
    Card,
    MensagemSemDados,
    RequisicaoModal,
    ProdutoDadosPrincipais,
    ProdutoEstoques,
    ProdutoPrecos,
    ProdutoPesoMedidas,
    ProdutoImagens,
    ProdutoCaracteristicas,
    ProdutoAdicionarVariacao,
    ProdutoEditarVariacao,
    ProdutoAnuncios,
  },
  emits: ['sincronizarRegistro', 'update:operacao', 'update:visivel'],
  setup(props, { emit }) {
    const refAdicionarVariacao = ref<InstanceType<typeof ProdutoAdicionarVariacao>>();
    const refEditarVariacao = ref<InstanceType<typeof ProdutoEditarVariacao>>();

    const { telaBase, apresentarMensagemAlerta, apresentarMensagemSucesso } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso,
      defineNovaOperacao, defineTextoPadraoBotoes, sincronizarRegistro,
    } = useModalBase(props, emit);
    const servicoProduto = new ServicoProduto();
    const servicoEstoque = new ServicoEstoque();
    const servicoIntegracao = new ServicoIntegracao();

    telaBase.identificadorRecurso = 'CADASTRO_PRODUTOS';

    const state = reactive({
      windowWidth: window.innerWidth,
      produto: {} as IProduto,
      caracteristicasCadastradas: [] as ICaracteristica[],
      estoquesCadastrados: [] as IEstoque[],
      itensEstoqueModelo: [] as IEstoqueItem[],
      integracoesAnuncios: [] as IIntegracao[],
    });

    state.caracteristicasCadastradas = [];
    state.estoquesCadastrados = [];
    state.itensEstoqueModelo = [];

    function objetoInicial() {
      state.produto = {} as IProduto;
      state.produto.codigo = 0;
      state.produto.nome = '';
      state.produto.descricao = '';
      state.produto.genero = EGenero.Unissex;
      state.produto.tipo = ETipoProduto.ProdutoSimples;
      state.produto.tempoGarantia = 0;
      state.produto.ativo = true;
      state.produto.definicoes = [];
      const definicaoPadrao: IProdutoDefinicao = {} as IProdutoDefinicao;
      definicaoPadrao.codigo = 0;
      definicaoPadrao.ativa = true;
      definicaoPadrao.codigoInterno = '';
      definicaoPadrao.definicaoPreco = EDefinicaoPreco.PrecoManualAnuncio;
      definicaoPadrao.imagens = [];
      state.produto.definicoes.push(definicaoPadrao);
      state.produto.caracteristicas = [];
    }

    async function comportamentoVariacoes() {
      if (state.produto.tipo === ETipoProduto.ProdutoComVariacao) {
        state.produto.definicoes = [];
        if (!UtilitarioGeral.validaLista(state.caracteristicasCadastradas)) {
          state.caracteristicasCadastradas = await new ServicoCaracteristica().obterTodasCaracteristicas();
        }
      } else if (UtilitarioGeral.validaLista(state.produto.definicoes)) {
        if (state.produto.definicoes.length > 1) {
          const primeiraDefinificao = UtilitarioGeral.instanciaObjetoLocal(state.produto.definicoes[0]);
          state.produto.definicoes = [];
          state.produto.definicoes.push(primeiraDefinificao);
        }
      }
    }

    async function salvar(salvarNovo: boolean) {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };
      apresentarBarraProgresso();

      if (state.produto.codigo === 0 || props.operacao.codigoRegistroDuplicar > 0) {
        retorno = await servicoProduto.incluir(state.produto);
      } else {
        retorno = await servicoProduto.alterar(state.produto);
      }

      ocultarBarraProgresso();

      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        if (state.produto.codigo === 0) {
          sincronizarRegistro(retorno.codigoRegistro);
          state.produto.codigo = retorno.codigoRegistro;
        } else {
          sincronizarRegistro(props.operacao.codigoRegistro);
        }
        apresentarMensagemSucesso(retorno.mensagem);
        if (salvarNovo) {
          objetoInicial();
          defineNovaOperacao(props.operacao);
        } else {
          state.produto = await servicoProduto.obter(state.produto.codigo);

          if (UtilitarioGeral.validaLista(state.produto.caracteristicas)) {
          for (let index = 0; index < state.produto.caracteristicas.length; index += 1) {
            const característica = state.caracteristicasCadastradas.find((c) => c.codigo === state.produto.caracteristicas[index].codigoCaracteristica);
            if (característica !== undefined) {
              state.produto.caracteristicas[index].tipo = característica.tipo;
            }
          }
        }
        }
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      telaBase.contratantesSelecionados = [];
      objetoInicial();
      if (modalBase.computedVisivel) {
        defineTextoPadraoBotoes(props.operacao.codigoRegistro === 0);
        state.caracteristicasCadastradas = await new ServicoCaracteristica().obterTodasCaracteristicas();
        state.estoquesCadastrados = await servicoEstoque.obterTodosEstoques();
        state.integracoesAnuncios = await servicoIntegracao.obterIntegracoes();
        state.integracoesAnuncios = state.integracoesAnuncios.filter((c) => c.tipo !== ETipoIntegracao.AnyMarket);

        if (props.operacao.codigoRegistroDuplicar > 0) {
          state.produto = await servicoProduto.obter(props.operacao.codigoRegistroDuplicar);
          state.produto.codigo = 0;
        } else if (props.operacao.codigoRegistro > 0) {
          state.produto = await servicoProduto.obter(props.operacao.codigoRegistro);
        }

        if (UtilitarioGeral.validaLista(state.produto.caracteristicas)) {
          for (let index = 0; index < state.produto.caracteristicas.length; index += 1) {
            const característica = state.caracteristicasCadastradas.find((c) => c.codigo === state.produto.caracteristicas[index].codigoCaracteristica);
            if (característica !== undefined) {
              state.produto.caracteristicas[index].tipo = característica.tipo;
            }
          }
        }

        if (UtilitarioGeral.validaLista(state.produto.definicoes)) {
          for (let index = 0; index < state.produto.definicoes.length; index += 1) {
            if (UtilitarioGeral.validaLista(state.produto.definicoes[index].estoques)) {
              state.itensEstoqueModelo.forEach((itemEstoque) => {
                if (!state.produto.definicoes[index].estoques.some((c) => c.codigoEstoque === itemEstoque.codigoEstoque)) {
                  state.produto.definicoes[index].estoques.push(itemEstoque);
                }
              });
            } else {
              state.produto.definicoes[index].estoques = UtilitarioGeral.instanciaObjetoLocal(state.itensEstoqueModelo);
            }
          }
        }
      }
      telaBase.carregando = false;
    });

    function adicionarNovaVariacao() {
      if (refAdicionarVariacao.value) {
        if (!UtilitarioGeral.valorValido(state.produto.nome)) {
          apresentarMensagemAlerta('É necessário informar o nome do produto antes de criar a variação!');
          return;
        }

        refAdicionarVariacao.value.adicionarVariacao();
      }
    }

    function editarVariacao(produtoDefinicao: IProdutoDefinicao, index: number) {
      if (refEditarVariacao.value) {
        refEditarVariacao.value.editarVariacao(produtoDefinicao, index);
      }
    }

    function removerVariacao(index: number) {
      state.produto.definicoes.splice(index, 1);
    }

    return {
      telaBase,
      props,
      servicoProduto,
      refAdicionarVariacao,
      modalBase,
      state,
      ETipoProduto,
      salvar,
      objetoInicial,
      adicionarNovaVariacao,
      comportamentoVariacoes,
      refEditarVariacao,
      editarVariacao,
      removerVariacao,
      UtilitarioGeral,
    };
  },
});
